export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
export const BACKEND_PORTAL = process.env.VUE_APP_BACKEND_PORTAL;
export default {
  ARROW: "➔",
  BACKEND_URL: BACKEND_URL,
  BACKEND_PORTAL: BACKEND_PORTAL,
  STATIC_URL: BACKEND_URL + "/static/lwl/",
  THEME: {
    primary: "#2167b5",
    secondary: "#081d34",
    fonts: {
      labels: "600 16px/20px Overpass",
    },
  },
  gis: {
    TILEZEN_API_KEY: "UFontT-8QXqifshooOgnVg",
    vogis: {
      BASE: "OSM",
      ORTHO:
        "https://vogis.lwl-center.com:4443/mapserver/mapserv?map=i_luftbilder_r_wms.map",
    },
    tiris: {
      BASE: "https://gis.lwl-center.com:4443/arcgis/rest/services/Basis/basis_karte/MapServer",
      ORTHO:
        "https://gis.lwl-center.com:4443/arcgis/rest/services/Basis/basis_ortho/MapServer",
    },
    crs: {
      31254: {
        NAME: "EPSG:31254",
        DEF: "+proj=tmerc +lat_0=0 +lon_0=10.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs",
        EXTENT: [-61418.2059, 173743.1182, 114487.3898, 285683.9475],
      },
      31255: {
        NAME: "EPSG:31255",
        DEF: "+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs",
        EXTENT: [-115771.3204, 130037.7189, 115359.4571, 408002.5351],
      },
      31256: {
        NAME: "EPSG:31256",
        DEF: "+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs",
        EXTENT: [-115317.3972, 151511.802, 64307.1064, 432456.2246],
      },
      31287: {
        NAME: "EPSG:31287",
        DEF: "+proj=lcc +lat_0=47.5 +lon_0=13.3333333333333 +lat_1=49 +lat_2=46 +x_0=400000 +y_0=400000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs +type=crs",
        EXTENT: [107724.11, 277804.37, 694938.75, 575953.62]
      },
      4326: {
        NAME: "EPSG:4326",
        DEF: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs ",
        EXTENT: [-180.0, -90.0, 180.0, 90.0],
      },
    },
  },
  endpoints: {
    ROOT: BACKEND_URL + "/api/",
    WHOAMI: BACKEND_URL + "/api/user/whoami/",
    PROJECTS: BACKEND_URL + "/api/project/",
    MATERIALS: BACKEND_URL + "/api/material/",
    UPLOAD: BACKEND_URL + "/api/project/",
    AUTH: BACKEND_URL + "/api/user/token/",
    USER: BACKEND_URL + "/api/user/",
    CADASTRAL: BACKEND_URL + "/api/cadastral/",
    PROVIDERS: BACKEND_URL + "/api/provider/",
  },
};
